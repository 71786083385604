import { useState, useEffect } from "react";
import React from "react";

type Size = "large" | "medium" | "small";
type Color = "red" | "green" | "blue" | "purple";
const colors: Color[] = ["red", "green", "blue", "purple"];
type SquareCircles = {
  large: Color | null;
  medium: Color | null;
  small: Color | null;
};
type Player = {
  color: Color | null;
  large: number;
  medium: number;
  small: number;
};

function Square({ value, onSquareClick }) {
  return (
    <button className="square" onClick={onSquareClick}>
      <div className={value.large && `circle large ${value.large}`}></div>
      <div className={value.medium && `circle medium ${value.medium}`}></div>
      <div className={value.small && `circle small ${value.small}`}></div>
    </button>
  );
}

function Board({
  playersNumber,
  circleSize,
  currentMove,
  currentPlayer,
  onPlay,
  squares,
  setSquares,
  winner,
  setWinner,
}: {
  playersNumber: number;
  circleSize: Size | null;
  currentMove: number;
  currentPlayer: Player;
  onPlay: Function;
  squares: SquareCircles[][];
  setSquares: Function;
  winner: Color | null;
  setWinner: Function;
}) {
  // const [squares, setSquares] = useState<Array<Array<SquareCircles>>>(
  //   Array(3).fill(Array(3).fill({ large: null, medium: null, small: null }))
  // );
  // const [winner, setWinner] = useState<Color | null>(null);
  // const [winColor, setWinColor] = useState<Color | null>(null);
  // const [winCoordinates, setWinCoordinates] = useState<
  //   Array<{
  //     x: number;
  //     y: number;
  //     s: Size;
  //   }>
  // >([]);

  function handleClick(i: number, j: number) {
    if (winner) return;
    const nextSquares = [...squares];
    nextSquares[i] = [...squares[i]];
    nextSquares[i][j] = { ...squares[i][j] };

    if (circleSize && !nextSquares[i][j][circleSize]) {
      // nextSquares[i][j][circleSize] = colors[currentMove % playersNumber];
      nextSquares[i][j][circleSize] = currentPlayer.color;
      setSquares(nextSquares);
      setWinner(
        calculateWinner(circleSize, nextSquares[i][j][circleSize], i, j)
      );
      onPlay();
    }
  }

  function calculateWinner(
    size: Size,
    color: Color | null,
    i: number,
    j: number
  ) {
    let win = true;
    for (let s in squares[i][j]) {
      if (s !== size && squares[i][j][s] !== color) {
        win = false;
      }
    }
    if (win) return color;
    function calculateBetween(a: SquareCircles, b: SquareCircles) {
      for (let s in a) {
        if (a[s] === color) {
          for (let ss in b) {
            if (b[ss] === color) {
              if (s !== size && ss !== size && s !== ss) {
                // setWinCoordinates([...winCoordinates, { x: 1, y: 0, s: size }]);
                // setWinColor(color);
                win = true;
              }
              if (s === size && s === ss) {
                win = true;
              }
            }
          }
        }
      }
    }
    if (i === 0 && j === 0) {
      calculateBetween(squares[0][1], squares[0][2]);
      calculateBetween(squares[1][0], squares[2][0]);
      calculateBetween(squares[1][1], squares[2][2]);
    }
    if (i === 1 && j === 0) {
      calculateBetween(squares[1][1], squares[1][2]);
      calculateBetween(squares[0][0], squares[2][0]);
    }
    if (i === 2 && j === 0) {
      calculateBetween(squares[2][1], squares[2][2]);
      calculateBetween(squares[0][0], squares[1][0]);
      calculateBetween(squares[1][1], squares[0][2]);
    }
    if (i === 0 && j === 1) {
      calculateBetween(squares[0][0], squares[0][2]);
      calculateBetween(squares[1][1], squares[2][1]);
    }
    if (i === 1 && j === 1) {
      calculateBetween(squares[1][0], squares[1][2]);
      calculateBetween(squares[0][1], squares[2][1]);
      calculateBetween(squares[0][0], squares[2][2]);
      calculateBetween(squares[2][0], squares[0][2]);
    }
    if (i === 2 && j === 1) {
      calculateBetween(squares[2][0], squares[2][2]);
      calculateBetween(squares[0][1], squares[1][1]);
    }
    if (i === 0 && j === 2) {
      calculateBetween(squares[0][0], squares[0][1]);
      calculateBetween(squares[1][2], squares[2][2]);
      calculateBetween(squares[1][1], squares[2][0]);
    }
    if (i === 1 && j === 2) {
      calculateBetween(squares[1][0], squares[1][1]);
      calculateBetween(squares[0][2], squares[2][2]);
    }
    if (i === 2 && j === 2) {
      calculateBetween(squares[2][0], squares[2][1]);
      calculateBetween(squares[0][2], squares[1][2]);
      calculateBetween(squares[0][0], squares[1][1]);
    }
    // let cells = [...squares[i].slice(0, j), ...squares[i].slice(j + 1)];
    // calculateBetween(cells[0], cells[1]);
    // // for (let s in cells[0]) {
    // //   if (cells[0][s] === color) {
    // //     for (let ss in cells[1]) {
    // //       if (cells[1][ss] === color) {
    // //         if (s !== size && ss !== size && s !== ss) {
    // //           win = true;
    // //         }
    // //         if (s === size && s === ss) {
    // //           win = true;
    // //         }
    // //       }
    // //     }
    // //   }
    // // }
    // let coll = [...squares.slice(0, i), ...squares.slice(i + 1)];
    // calculateBetween(coll[0][j], coll[1][j]);
    // const array1 = [
    //   [0, 0],
    //   [1, 1],
    //   [2, 2],
    // ];
    // const found = array1.find(
    //   (element) => element[0] === i && element[1] === j
    // );
    // console.log(found, " element");
    // for (let s in coll[0][j]) {
    //   if (coll[0][j][s] === color) {
    //     for (let ss in coll[1][j]) {
    //       if (coll[1][j][ss] === color) {
    //         if (s !== size && ss !== size && s !== ss) {
    //           win = true;
    //         }
    //         if (s === size && s === ss) {
    //           win = true;
    //         }
    //       }
    //     }
    //   }
    // }
    if (win) return color;
    return null;
  }

  return (
    <>
      {/* <Popup trigger={winner}>
        <div className="square">
          <div className={`circle large ${winner}`}></div>
          <div className={`circle medium ${winner}`}></div>
          <div className={`circle small ${winner}`}></div>
        </div>
        <h1>WINNER</h1>
        <div className="square">
          <div className={`circle large ${winner}`}></div>
          <div className={`circle medium ${winner}`}></div>
          <div className={`circle small ${winner}`}></div>
        </div>
      </Popup> */}
      <div className="game-board">
        {squares.map((row, i) => (
          <div className="board-row" key={`row-${i}`}>
            {row.map((box, j) => (
              <Square
                value={box}
                onSquareClick={() => handleClick(i, j)}
                key={`box-${i}-${j}`}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

function Popup(props) {
  return props.trigger ? (
    <div className="popup">
      <div className="popup-inner">{props.children}</div>
    </div>
  ) : (
    ""
  );
}

function PlayerInfo(props) {
  return (
    <div className="info">
      <div className="board-row">
        <button
          className="square number"
          onClick={() => props.setCircleSize("large")}
        >
          <div className={`circle large ${props.player.color}`}></div>
          {props.player.large}
        </button>
        <button
          className="square number"
          onClick={() => props.setCircleSize("medium")}
        >
          <div className={`circle medium ${props.player.color}`}></div>
          {props.player.medium}
        </button>
        <button
          className="square number"
          onClick={() => props.setCircleSize("small")}
        >
          <div className={`circle small ${props.player.color}`}></div>
          {props.player.small}
        </button>
      </div>
    </div>
  );
}

export default function Game() {
  const [squares, setSquares] = useState<Array<Array<SquareCircles>>>(
    Array(3).fill(Array(3).fill({ large: null, medium: null, small: null }))
  );
  const [circleSize, setCircleSize] = useState<Size | null>(null);
  const [currentMove, setCurrentMove] = useState(0);
  const [currentPlayer, setCurrentPlayer] = useState<Player>({
    color: colors[0],
    large: 3,
    medium: 3,
    small: 3,
  });
  const [playersNumber, setPlayersNumber] = useState(0);
  const [player, setPlayer] = useState<Array<Player>>(
    []
    // Array().fill({ color: colors[0], large: 3, medium: 3, small: 3 })
  );
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [winner, setWinner] = useState<Color | null>(null);

  function handleSetCircleSize(size: Size) {
    if (currentPlayer[size] > 0) {
      setCircleSize(size);
    }
  }

  function handlePlay(circleSize) {
    const n = currentMove % playersNumber;
    const p = [...player];
    p[n] = { ...player[n] };
    --p[n][circleSize];
    setPlayer(p);
    setCurrentMove(currentMove + 1);
    setCurrentPlayer(player[(currentMove + 1) % playersNumber]);
    setCircleSize(null);
  }

  function playAgain() {
    setSquares(
      Array(3).fill(Array(3).fill({ large: null, medium: null, small: null }))
    );
    setCurrentMove(0);
    setCurrentPlayer(player[0]);
    setWinner(null);
    definePlayerList(playersNumber);
    // setCurrentPlayer(player[0]);
  }

  function definePlayerList(n: number) {
    const p: [Player] = [{ color: null, large: 0, medium: 0, small: 0 }];
    for (let i = 0; i < n; i++) {
      p[i] = {
        color: colors[i],
        large: 3,
        medium: 3,
        small: 3,
      };
    }
    setPlayer([...p]);
    setCurrentPlayer(p[0]);
  }

  function handleSellectingPlayersNumber(n: number) {
    if (playersNumber !== n) {
      playAgain();
      setPlayersNumber(n);
      // const p: [Player] = [{ color: null, large: 0, medium: 0, small: 0 }];
      // for (let i = 0; i < n; i++) {
      //   p[i] = {
      //     color: colors[i],
      //     large: 3,
      //     medium: 3,
      //     small: 3,
      //   };
      // }
      // setPlayer([...p]);
      // setCurrentPlayer(p[0]);
      definePlayerList(n);
    }
    setSettingsPopup(false);
  }

  useEffect(() => {
    setTimeout(() => {
      setSettingsPopup(true);
    }, 2000);
  }, []);

  return (
    <div className="game">
      <button
        className="settings square"
        onClick={() => setSettingsPopup(true)}
      >
        <span className="number">{playersNumber}</span> players
      </button>
      <Popup trigger={settingsPopup} setTrigger={setSettingsPopup}>
        <div className="board-row">
          <button
            className="square"
            onClick={() => handleSellectingPlayersNumber(2)}
          >
            <span className="number">2</span>
            <br /> players
          </button>
          <button
            className="square"
            onClick={() => handleSellectingPlayersNumber(3)}
          >
            <span className="number">3</span>
            <br /> players
          </button>
          <button
            className="square"
            onClick={() => handleSellectingPlayersNumber(4)}
          >
            <span className="number">4</span>
            <br /> players
          </button>
        </div>
      </Popup>
      <Popup trigger={winner}>
        <div className="square">
          <div className={`circle large ${winner}`}></div>
          <div className={`circle medium ${winner}`}></div>
          <div className={`circle small ${winner}`}></div>
        </div>
        <h1>WINNER</h1>
        {/* <div className="square">
          <div className={`circle large ${winner}`}></div>
          <div className={`circle medium ${winner}`}></div>
          <div className={`circle small ${winner}`}></div>
        </div> */}
        <div className="square" onClick={playAgain}>
          Play Again
        </div>
      </Popup>
      <Board
        playersNumber={playersNumber}
        circleSize={circleSize}
        currentMove={currentMove}
        currentPlayer={currentPlayer}
        onPlay={() => handlePlay(circleSize)}
        squares={squares}
        setSquares={setSquares}
        winner={winner}
        setWinner={setWinner}
      />
      {currentPlayer && (
        <PlayerInfo
          player={currentPlayer}
          setCircleSize={handleSetCircleSize}
          key={currentPlayer}
        />
      )}
    </div>
  );
}
